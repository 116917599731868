import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import Video from "../../components/video"

import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Steven J. Grant",
    description:
      "Steven J. Grant is experienced in personal injury, landlord-tenant law, and collections. He is also a former assistant clerk for Waterbury Superior Court. Contact us today for his legal services.",
    heroH1: "Steven J. Grant",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Steven J. Grant</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/VPTclBBftrk?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Steven J. Grant is an associate practicing primarily in the area
                of Probate, personal injury, landlord tenant law and collections.
                Prior to joining the firm, Mr. Grant worked as a Temporary
                Assistant Clerk for the Waterbury Superior Court in the Caseflow
                office with the Hon. Joseph H. Pellegrino and the Hon. Robert L.
                Holzberg, and during law school as a judicial intern for Judge
                Richard A. Damiani.
              </p>
              <p className="mb-8">
                Mr. Grant graduated cum laude from the University of Connecticut
                in 1994 with a Bachelor of Arts degree in Political Science. He
                graduated from Quinnipiac University School of Law in 1997. At
                Quinnipiac, Mr. Grant received the Community Service Award.
              </p>
              <p className="mb-8">
                Mr. Grant previously served as the President of the Connecticut
                Italian American Bar Association, and remains active with the
                organization. He serves as the Chairman of the Town of Wolcott’s
                Zoning Board of Appeals, where he resides with his wife and
                daughter.
              </p>
            </div>
            <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
              <figure style={{ height: "150px", width: "150px" }}>
                <StaticImage
                  className="rounded-l-2xl"
                  height={"150"}
                  quality="100"
                  src="../../images/atty_grant.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Steven J. Grant"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                  }}
                />
              </figure>
              <div class="px-4 pb-2 text-gray-900">
                <p className="text-xl font-bold">Steven J. Grant</p>
                <p className="">Associate</p>
                <p className="">
                  <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                </p>
                <p className="">
                  203-250-1835 <strong>fax</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
